import Footer from "../../components/Footer/Footer";
import "./home.css";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import "./profile.css";
import "./addmn.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import swal from "sweetalert";
function AddBank() {
    const [profile, setProfile] = useState(null);
    const [show, setShow] = useState(false);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const [bank, setBank] = useState(null);
    const navigate = useNavigate();
    useEffect(() => {
        axios
            .get(`https://server.vascara-group.com/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch(() => {
                localStorage.removeItem("user");
                navigate("/login");
            });
        axios
            .get(`https://server.vascara-group.com/bank/getBank`, {})
            .then((res) => {
                setBank(res.data.data);
            })
            .catch((err) => setBank(null));
    }, []);
    const onSubmit = (data) => {
        const formData = {
            name_bank: data.name_bank,
            stk: data.stk,
            fullname: data.fullname,
            user: profile._id
        };
        axios
            .post(`https://server.vascara-group.com/bank/create`, formData)
            .then((res) => {
                swal({
                    title: "Thành công",
                    text: "Thêm tài khoản ngân hàng thành công",
                    icon: "success",
                    buttons: "Đồng ý"
                }).then(() => navigate("/mine"));
            })
            .catch((err) =>
                setError("money", {
                    type: "minLength",
                    message: "Lỗi giao dịch 404!"
                })
            );
    };
    return (
        <>
            <div className="app1">
                <div className="header_profile">
                    <div className="info_profile">
                        <div></div>
                        <div className="avatar_profile">
                            <div className="name_profile"></div>

                            <div
                                style={{
                                    marginLeft: "20px",
                                    fontSize: "1.2rem",
                                    fontWeight: 600,
                                    color: "white"
                                }}>
                                Thêm tài khoản ngân hàng
                            </div>
                        </div>
                        <Link to="/mine" className="icon_setting_header">
                            &times;
                        </Link>
                    </div>
                </div>
                <div className="content_profile">
                    <div className="list-bank">
                        {bank != null ? (
                            <>
                                <form
                                    style={{
                                        display: "block",
                                        padding: "15px",
                                        textAlign: "left"
                                    }}
                                    onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                        <div>
                                            <label>Tên ngân hàng</label>
                                            <input
                                                className="ipadd"
                                                type="text"
                                                value={bank[0].name_bank}
                                                readonly=""
                                                placeholder="Nhập tên ngân hàng"
                                                {...register("name_bank", { required: true })}
                                            />
                                        </div>
                                        <div>
                                            <label>Số tài khoản</label>
                                            <input
                                                className="ipadd"
                                                type="text"
                                                value={bank[0].stk}
                                                readonly=""
                                                placeholder="Nhập số tài khoản"
                                                {...register("stk", { required: true })}
                                            />
                                        </div>
                                        <div>
                                            <label>Chủ tài khoản</label>
                                            <input
                                                className="ipadd"
                                                type="text"
                                                value={bank[0].fullname}
                                                readonly=""
                                                placeholder="Nhập họ tên người nhận"
                                                {...register("fullname", { required: true })}
                                            />
                                        </div>
                                        <button type="submit" className="btn-submit" style={{ background: "red", color: "#fff" }} onClick={() => navigate("/bank/" + bank[0]._id)}>
                                            Sửa đổi thông tin
                                        </button>
                                        {navigate("/bank/" + bank[0]._id)}
                                    </div>
                                </form>
                            </>
                        ) : (
                            <>
                                <form
                                    style={{
                                        display: "block",
                                        padding: "15px",
                                        textAlign: "left"
                                    }}
                                    onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                        <div>
                                            <label>Tên ngân hàng</label>
                                            <input className="ipadd" type="text" {...register("name_bank", { required: true })} placeholder="Nhập tên ngân hàng" />
                                        </div>
                                        <div>
                                            <label>Số tài khoản</label>
                                            <input className="ipadd" type="number" {...register("stk", { required: true })} placeholder="Nhập số tài khoản" />
                                        </div>
                                        <div>
                                            <label>Chủ tài khoản</label>
                                            <input className="ipadd" type="text" {...register("fullname", { required: true })} placeholder="Nhập họ tên người nhận" />
                                        </div>
                                        <button type="submit" className="btn-submit">
                                            Xác nhận
                                        </button>
                                    </div>
                                </form>
                            </>
                        )}
                    </div>
                </div>

                <Footer profile={profile} />
            </div>
        </>
    );
}
export default AddBank;
