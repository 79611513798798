import { Link, useNavigate } from "react-router-dom";
import "./home.css";
import "./profile.css";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import RestoreIcon from "@mui/icons-material/Restore";
import CachedIcon from "@mui/icons-material/Cached";
import Footer from "../../components/Footer/Footer";
import swal from "sweetalert";
import Confetti from "react-confetti";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";

function Game1() {
    SwiperCore.use([Autoplay]);
    const swiperParams = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        }
    };
    const [isVisible, setVisible] = useState(null);
    const [bet, setBet] = useState(null);
    const [profile, setProfile] = useState(null);
    const [second, setSecond] = useState(0);
    const [minute, setMinute] = useState(1);
    const [start, setStart] = useState(false);
    const [dulieunhap, setDulieunhap] = useState(new Date());
    const [update, setUpdate] = useState(0);
    const [err, setErr] = useState(null);
    const [active, setActive] = useState(null);
    const date = new Date();
    const currentMinute = date.getMinutes();
    const currentSecond = date.getSeconds();
    const [state, setState] = useState(null);
    const [total, setTotal] = useState(null);
    const [isShow, setShow] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [choose, setChoose] = useState(null);
    const [quantity, setQuantity] = useState(100);
    const {
        watch,
        register,
        handleSubmit,
        setError,
        getValues,
        formState: { errors }
    } = useForm();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        if (start === false) {
            axios
                .get(`https://server.vascara-group.com/auth/getUser`, {})
                .then((res) => {
                    setProfile(res.data.data);
                })
                .catch(() => {
                    localStorage.removeItem("user");
                    navigate("/login");
                });
            axios.get(`https://server.vascara-group.com/bet1/get`).then((res) => {
                setBet(res.data.data);
                setDulieunhap(new Date(res.data.data.createdAt));
                setStart(true);
            });
            axios
                .get(`https://server.vascara-group.com/bet1/getallbet`, {})
                .then((res) => {
                    setTotal(res.data.data);
                })
                .catch(() => setTotal(null));
        }
    }, [start]);
    useEffect(() => {
        const interval = setInterval(() => {
            axios.get(`https://server.vascara-group.com/notification/getnotifi`, {}).then((res) => {
                if (res?.data.data[0]) {
                    setVisible({
                        money: res?.data?.data[0]?.money.toLocaleString(),
                        id: res?.data?.data[0]?._id,
                        msg: res?.data?.data[0]?.msg
                    });
                }
            });
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        let swalInst;
        const showAlert = async (data) => {
            setShowConfetti(true);
            swalInst = swal({
                title: "Xin chúc mừng !!!",
                text: `Chúc mừng quý khách có mã ID ${
                    Number(profile?.iduser) + 3000
                } đã nhận được giải thưởng ngẫu nhiên trị giá ${data?.money?.toLocaleString()}. Vui lòng liên hệ CSKH để biết thêm chi tiết.`,
                //text: data.msg,
                className: "bg-gift",
                //icon: GiftImg,
                buttons: {
                    submit: "Đồng ý"
                }
            }).then(() => {
                setShowConfetti(false);
                axios.post("https://server.vascara-group.com/notification/seen", {
                    id: data.id
                });
            });
            setVisible(false);
        };
        if (isVisible) {
            showAlert(isVisible);
        }
    }, [isVisible]);
    useEffect(() => {
        let curTime_second = Math.floor(60 - (date - dulieunhap) / 1000);

        let myTimeout;
        // if (curTime_second <= 0) {
        // 	axios.get(`https://server.vascara-group.com//bet/get`).then((res) => {
        // 		let date = new Date();
        // 		let date1 = new Date(res.data.data[0].createdAt);
        // 		if (date - date1 > 60000) {
        // 			setBet(null);
        // 		} else {
        // 			setBet(res.data.data[0]);
        // 			setDulieunhap(new Date(res.data.data[0].createdAt));
        // 		}
        // 	});
        // }

        if (currentMinute === dulieunhap.getMinutes() && currentSecond === dulieunhap.getSeconds()) {
            setStart(true);
            setSecond(second - 1);
            return () => {
                clearTimeout(myTimeout);
            };
        } else if (curTime_second < 60 && curTime_second >= 0) {
            setSecond(curTime_second % 60);
            setMinute((curTime_second - (curTime_second % 60)) / 60);
            setStart(true);
            return () => {
                clearTimeout(myTimeout);
            };
        } else {
            //cập nhật thời gian hiện tại 0.5s/lần
            myTimeout = setTimeout(() => {
                setUpdate(update + 1);
            }, 500);
        }
    }, [update, dulieunhap]);

    useEffect(() => {
        let curTime_second = Math.floor(60 - (date - dulieunhap) / 1000);
        let myTimeout = 0;
        if (start) {
            setSecond(curTime_second % 60);
            setMinute(Math.floor(curTime_second / 60));

            if (curTime_second > 60 || curTime_second <= 0) {
                setStart(false);
                setMinute(1);
                setSecond(0);
                return () => {
                    clearTimeout(myTimeout);
                };
            }
            myTimeout = setTimeout(() => {
                setSecond(second - 1);
            }, 1000);
        }
        return () => {
            clearTimeout(myTimeout);
        };
    }, [second, start, dulieunhap]);

    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const onChoose = (e) => {
        setChoose(e);
        setActive(e);
        setShowPopup(true);
    };
    const onSubmit = async (data) => {
        if (quantity == 0) {
            swal("Thông báo", "Vui lòng nhập số điểm", "info");
            return;
        }
        if (!choose) {
            swal("Thông báo", "Vui lòng chọn ô", "warning");
            return;
        }
        const formDate = {
            id: bet._id,
            money: quantity,
            bet: choose,
            type_game: localStorage.getItem("banner") || 3
        };

        axios
            .post(`https://server.vascara-group.com/history1/choose`, formDate)
            .then((res) => {
                swal("Thành công", "Nhập thành công", "success");
                setStart(false);
                setErr(res.data.message);
                setShowPopup(false);
            })
            .catch((res) => swal("error", res.response.data.message, "error"));
    };

    const handleClose = () => {
        setShowPopup(false);
    };

    const handleBackdropClick = (event) => {
        if (event.target === event.currentTarget) {
            setShowPopup(false);
        }
    };

    const [isOpen, setIsOpen] = useState(false);

    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
    };

    const handleSetValue = (value) => {
        setQuantity(value);
    };

    const resetForm = () => {
        setQuantity(100);
        setActive(null);
        setState(null);
        setShowPopup(false);
    };
    return (
        <>
            <div className="app1">
                <div style={{ background: "#fff" }}>
                    <div data-v-69b3b4a1="" data-v-0f9d40fc="" className="lottery-header theme-main-bg theme-header-bottom" data-v-435dc89a="">
                        <div style={{ fontWeight: "bold" }}>VASCARA</div>
                        <div className="text1" style={{ marginLeft: "auto", whiteSpace: "nowrap" }}>
                            Mã sản phẩm hiện tại {total ? total[0]?.id_bet : "----"}
                        </div>
                        <div style={{ width: "35px", textAlign: "right", marginLeft: "auto" }}>
                            <div className="info-icon" onClick={openPopup}>
                                <InfoIcon />
                            </div>
                        </div>
                    </div>
                    <div className="result_last">
                        {total != null && (
                            <ul data-v-4a0db828="" className="clear-fix list">
                                <li data-v-4a0db828="" className="num-item ball   i0 number1 numberlist">
                                    {total[0].result.split(" ")[0]}
                                </li>
                                <li data-v-4a0db828="" className="num-item ball  i1 number2 numberlist">
                                    {total[0].result.split(" ")[1]}
                                </li>
                                <li data-v-4a0db828="" className="num-item ball  i2 number3 numberlist">
                                    {total[0].result.split(" ")[2]}
                                </li>
                                <li data-v-4a0db828="" className="num-item ball  i3 number4 numberlist">
                                    {total[0].result.split(" ")[3]}
                                </li>
                                <li data-v-4a0db828="" className="num-item ball  i4 number5 numberlist">
                                    {total[0].result.split(" ")[4]}
                                </li>
                            </ul>
                        )}
                    </div>
                    <div data-v-69b3b4a1="" data-v-0f9d40fc="" className="lottery-header theme-main-bg theme-header-bottom" data-v-435dc89a="">
                        <div className="text1">Mã sản phẩm tiếp theo: {bet ? bet.id_bet : "----"}</div>
                        <div style={{ width: "100px", justifyContent: "end", marginLeft: "auto", display: "flex", alignItems: "center" }}>
                            <RestoreIcon style={{ marginRight: "3px" }} /> 0{minute} : {second < 10 ? "0" : ""}
                            {second}
                        </div>
                    </div>
                </div>
                <div className="record_bet">
                    <nav data-v-260e3e17="" data-v-0f9d40fc="" className="tab-box lottery-game-tab" data-v-435dc89a="">
                        <div data-v-260e3e17="" className="tab-item tab-item-active">
                            Thời trang
                        </div>
                        <div data-v-260e3e17="" className="tab-item" onClick={() => navigate("/game3")}>
                            Sản phẩm
                        </div>
                        <div data-v-260e3e17="" className="tab-item" onClick={() => navigate("/game5")}>
                            Khách hàng
                        </div>
                    </nav>
                    <div className="button-demo">
                        <div>
                            <button>Quần áo</button>
                        </div>
                        <div>
                            <button>Túi xách</button>
                        </div>
                        <div>
                            <button>Giày dép</button>
                        </div>
                        <div>
                            <button>Phụ kiện</button>
                        </div>
                        <div>
                            <button>Nước hoa</button>
                        </div>
                    </div>
                    <div className="bet-input" style={{ padding: "10px" }}>
                        <div style={{ color: "#387bea", width: "40px", display: "none" }}>&nbsp;</div>
                        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                            <div
                                style={{
                                    background: "#ff9521",
                                    color: "#fff",
                                    padding: "12px 18px",
                                    borderRadius: "5px",
                                    fontSize: "16px",
                                    fontWeight: "bold"
                                }}>
                                Bảng phân phối công việc
                            </div>
                        </div>
                        <div style={{ width: "40px", textAlign: "right", display: "none" }} onClick={() => window.location.reload()}>
                            <CachedIcon sx={{ fontSize: "28px" }} />
                        </div>
                    </div>
                    <ul data-v-0f9d40fc="" className="lottery-code-panel-body">
                        <li data-v-0f9d40fc="" className="ybcp-num-item">
                            <span
                                data-v-0f9d40fc=""
                                className={`lottery-code-item-name ${active == 1 && "active"}`}
                                onClick={(e) => {
                                    onChoose(1);
                                }}>
                                TRÊN
                            </span>
                        </li>
                        <li data-v-0f9d40fc="" className="ybcp-num-item">
                            <span
                                data-v-0f9d40fc=""
                                className={`lottery-code-item-name lottery-code-item-odds ${active == 2 && "active"}`}
                                onClick={(e) => {
                                    onChoose(2);
                                }}>
                                DƯỚI
                            </span>
                        </li>
                        {/*<li data-v-0f9d40fc="" className="ybcp-num-item">
                            <span
                                data-v-0f9d40fc=""
                                className={`lottery-code-item-name ${active == 3 && "active"}`}
                                onClick={(e) => {
                                    onChoose(3);
                                }}>
                                LÊN
                                <span data-v-0f9d40fc="" className="lottery-code-item-odds">
                                    1.99{" "}
                                </span>
                            </span>
                        </li>
                        <li data-v-0f9d40fc="" className="ybcp-num-item">
                            <span
                                data-v-0f9d40fc=""
                                className={`lottery-code-item-name ${active == 4 && "active"}`}
                                onClick={(e) => {
                                    onChoose(4);
                                }}>
                                XUỐNG
                                <span data-v-0f9d40fc="" className="lottery-code-item-odds">
                                    1.99{" "}
                                </span>
                            </span>
                            </li>*/}
                    </ul>
                    <div className="grid-6">
                        <div>
                            <img src="/img/quan_ao.webp" />
                            <span>Quần áo</span>
                        </div>
                        <div>
                            <img src="/img/tui_xach.webp" />
                            <span>Túi xách</span>
                        </div>
                        <div>
                            <img src="/img/giay_dep.webp" />
                            <span>Giày dép</span>
                        </div>
                        <div>
                            <img src="/img/phu_kien.webp" />
                            <span>Phụ kiện</span>
                        </div>
                        <div>
                            <img src="/img/that_lung.webp" />
                            <span>Mỹ phẩm</span>
                        </div>
                        <div>
                            <img src="/img/nuoc_hoa.png" />
                            <span>Nước hoa</span>
                        </div>
                    </div>
                    <div style={{ padding: "10px 10px 80px" }}>
                        <Swiper {...swiperParams}>
                            <SwiperSlide>
                                <img src="/img/banner1.webp" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="/img/banner2.webp" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="/img/banner3.webp" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="/img/banner4.webp" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src="/img/banner5.webp" />
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className="popup-hd">
                    <div className="popup-hd-header">
                        <h3>QUY ĐỊNH</h3>
                        <div className="popup-hd-close" onClick={closePopup}>
                            &times;
                        </div>
                    </div>
                    <div className="popup-hd-content">
                        VASCARA nghiêm cấm mọi hình thức mua hàng bằng danh tính giả, khi bị phát hiện, website có quyền rút toàn bộ số tiền thưởng. Nếu bạn có bất kỳ nhận
                        xét hoặc đề xuất nào, vui lòng chọn "CSKH" trong web và để lại tin nhắn.
                        <br />
                        <br />
                        Lưu ý web sẽ trích những phần quà ngẫu nhiên cho ID may mắn hàng tháng
                        <br />
                        <br />
                        <strong>Điều kiện nhận thưởng :</strong>
                        <br />
                        Xác minh thủ tục theo chứng từ hóa đơn hệ thống khảo sát thành công mới đủ điều kiện nhận thưởng
                        <br />
                        <br />
                        Để đảm bảo hệ thống được hoạt động lâu dài cũng như bắt buộc duy trì các hoạt động đóng thuế cho doanh nghiệp, đối với các quý khách nhận được phần quà ngẫu
                        nhiên may mắn từ hệ thống, khi rút tiền cần thực hiện đóng phí duy trì theo hạn mức rút tiền như sau:
                        <br />
                        <br />
                        <strong>Thuế:</strong>
                        <br />
                        - Đối với Quý khách hàng rút hạn mức từ 150.000 điểm - 250.000 điểm vui lòng đóng mức thuế 15% / tổng số điểm Quý khách rút ra.
                        <br />
                        - Đối với Quý khách hàng rút hạn mức từ 250.000 điểm - 499.000 điểm vui lòng đóng mức thuế 20% / tổng số điểm Quý khách rút ra.
                        <br />- Đối với Quý khách hàng rút hạn mức trên 500.000 điểm tương ứng 500.000.000 VNĐ vui lòng đóng mức thuế 30% / tổng số điểm Quý khách rút ra.
                    </div>
                </div>
            )}
            {showConfetti && (
                <Confetti
                    style={{
                        width: "100vw",
                        height: "100vh",
                        maxWidth: "540px",
                        zIndex: "999999"
                    }}
                />
            )}
            {showPopup && (
                <div className="lottery-bet-bg">
                    <form className="lottery-bet-input" onSubmit={handleSubmit(onSubmit)}>
                        <div className="chip-title" style={{margin:"0 0 20px"}}>Nhập số điểm</div>
                        <div className="chip-row">
                            <div className={`chip ${quantity == 100 ? "active" : ""}`} onClick={() => handleSetValue(100)}>
                                100
                            </div>
                            <div className={`chip ${quantity == 500 ? "active" : ""}`} onClick={() => handleSetValue(500)}>
                                500
                            </div>
                            <div className={`chip ${quantity == 1000 ? "active" : ""}`} onClick={() => handleSetValue(1000)}>
                                1000
                            </div>
                            <div className={`chip ${quantity == 10000 ? "active" : ""}`} onClick={() => handleSetValue(10000)}>
                                10000
                            </div>
                            <div className={`chip ${quantity == 20000 ? "active" : ""}`} onClick={() => handleSetValue(20000)}>
                                20000
                            </div>
                        </div>
                        <div className="bet-input">
                            <div className="btn-close-bet" onClick={resetForm}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="#eee"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    style={{ position: "relative", top: "2px", margin: "0 3px 0 0" }}>
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M4 7l16 0" />
                                    <path d="M10 11l0 6" />
                                    <path d="M14 11l0 6" />
                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                </svg>
                                Hủy
                            </div>
                            <div>
                                <input {...register("money", { required: true })} type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} name="money" placeholder="Nhập số điểm" />
                            </div>
                            <div>
                                <button type="submit">Xác nhận</button>
                            </div>
                        </div>
                        
                    </form>
                </div>
            )}
            <Footer profile={profile} />
        </>
    );
}
export default Game1;
