import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function Register1() {
	const [err, setErr] = useState(null);
	const login = localStorage.getItem("user");
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();
	useEffect(() => {
		if (login) {
			navigate("/");
		}
	}, []);
	const onSubmit = (data) => {
		const regex = /^[a-zA-Z0-9]+$/;
		if (data.username.length < 6) {
			setError("username", {
                type: "minLength",
                message: "Tên đăng nhập tối thiểu 6 kí tự"
            });
			return;
		}
		/*if (!regex.test(data.username)) {
			setError("username", {
				type: "minLength",
				message: "Tên đăng nhập không chứa khoảng trắng và ký tự đặc biệt",
			});
			return;
		}*/
		if (data.password.length < 6) {
			setError("password", {
				type: "minLength",
				message: "Mật khẩu tối thiểu 6 kí tự",
			});
			return;
		}
		if (data.password !== data.ippassword) {
			setError("ippassword", {
				type: "minLength",
				message: "Nhập lại mật khẩu không chính xác",
			});
			return;
		}
		axios
            .post(`https://server.vascara-group.com/auth/register`, {
                username: data.username.toLowerCase(),
                password: data.password,
                ippassword: data.ippassword
            })
            .then((res) => {
                swal({
                    title: "Thông báo",
                    text: "Đăng ký thành công",
                    icon: "success",
                    timer: 2000,
                    buttons: false
                }).then((value) => {
                    if (value) {
                        axios.post(`https://server.vascara-group.com/auth/login`, data).then((res) => {
                            localStorage.setItem("user", res.data.data);
                            navigate("/");
                        });
                    } else {
                        setTimeout(() => {
                            axios.post(`https://server.vascara-group.com/auth/login`, data).then((res) => {
                                localStorage.setItem("user", res.data.data);
                                navigate("/");
                            });
                        }, 1000);
                    }
                });
            })
            .catch((err) => {
                setErr(err.response.data.message ? err.response.data.message : err.message);
            });
	};
	const [showPassword, setShowPassword] = useState(false);
	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const [showPassword0, setShowPassword0] = useState(false);
	const toggleShowPassword0 = () => {
		setShowPassword0(!showPassword0);
	};
	return (
        <>
            <div className="app123">
                <div className="bg"></div>

                <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                    <img src="/img/login.jpeg" />
                    <h1>VASCARA</h1>
                    <div className="inputs">
                        <div>
                            <input type="text" {...register("username", { required: true })} className="ip-lg" placeholder="Tên đăng nhập" />
                            {errors.username ? <p>{errors.username.message}</p> : null}
                        </div>
                        <div>
                            <input type={showPassword0 ? "text" : "password"} className="ip-lg" {...register("password", { required: true })} placeholder="Mật khẩu" />
                            <div onClick={toggleShowPassword0} className="change-visible">
                                {showPassword0 ? <VisibilityOff sx={{ color: "#666" }} /> : <Visibility sx={{ color: "#666" }} />}
                            </div>
                            {errors.password ? <p>{errors.password.message}</p> : null}
                        </div>
                        <div>
                            <input type={showPassword ? "text" : "password"} className="ip-lg" {...register("ippassword", { required: true })} placeholder="Nhập lại mật khẩu" />
                            <div onClick={toggleShowPassword} className="change-visible">
                                {showPassword ? <VisibilityOff sx={{ color: "#666" }} /> : <Visibility sx={{ color: "#666" }} />}
                            </div>
                            {errors.ippassword ? <p>{errors.ippassword.message}</p> : null}
                        </div>
                    </div>
                    {err ? <p style={{ color: "red" }}>{err}</p> : null}
                    <p className="p-lg">
                        <Link className="a-lg" to="/login">
                            Đã có tài khoản ? Đăng nhập ngay
                        </Link>
                    </p>
                    <button type="submit" className="btn-lg">
                        Đăng ký
                    </button>
                </form>
            </div>
            {/*<div className="bg-bot">
                <img src={require("../../img/bg_bot.png")} />
            </div>*/}
        </>
    );
}
export default Register1;
